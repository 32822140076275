<template>
  <Popover class="fixed left-0 w-full top-0 z-20 bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="
          flex
          justify-between
          items-center
          py-6
          md:justify-start md:space-x-5
        "
      >
        <div class="flex justify-start w-0 flex-1">
          <router-link to="/">
            <span class="sr-only">Bryan And Hedden</span>
            <div class="flex items-center">
              <img
                class="h-9 w-auto md:hidden"
                src="@/assets/logo.svg"
                alt="Bryan And Hedden"
              />
			  <img
                class="h-10 w-auto hidden md:block"
                src="@/assets/logo-md.svg"
                alt="Bryan And Hedden"
              />
            </div>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-white
              hover:text-gray-500
              transition
              hover:bg-white
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6 text-accent" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup
          as="nav"
          class="
            hidden
            md:flex
            items-center
            space-x-3
            lg:space-x-5
            xl:space-x-7
          "
        >
          <NavItem
            v-for="item in navItems"
            :path="item.path"
            :key="item.label"
            :dropdownItems="item.dropdownItems"
          >
            {{ item.label }}
          </NavItem>
          <!--<button
            class="
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              transition
              btn-dark
            "
          >
            Client Login
          </button>-->
        </PopoverGroup>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="
          absolute
          top-0
          inset-x-0
          z-10
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-gray-200
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <router-link to="/">
                <img
                  class="h-5 w-auto"
                  src="@/assets/logo.svg"
                  alt="Bryan And Hedden"
                />
              </router-link>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    transition
                    hover:bg-accent-lightest
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-teal
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6 text-accent" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link
                  v-for="item in navItems"
                  :key="item.label"
                  :to="item.path"
                  class="nav-item-mobile"
                >
                  <span class="nav-item-mobile__text">{{ item.label }}</span>
                </router-link>
                <!--<Button class="mx-auto">Client Login</Button>-->
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <div style="margin-bottom: 4.5rem" />
</template>

<script>
import NavItem from "./NavItem.vue";
import Button from "./Button.vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const navItems = [
  { path: "/", label: "Home" },
  { path: "/about", label: "About Us" },
  {
    path: "/services",
    label: "Services",
  },
  { path: "/contact", label: "Contact" },
];
export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Button,
    MenuIcon,
    XIcon,
    NavItem,
  },
  setup() {
    return { navItems };
  },
};
</script>
