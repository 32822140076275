import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("@/views/Services.vue"),
  },
  {
    path: "/services/details",
    name: "Services Details",
    component: () => import("@/views/ServiceDetails.vue"),
  },
  {
    path: "/services/investment",
    name: "Investment",
    component: () => import("@/views/services/Investment.vue"),
  },
  {
    path: "/services/employee-benefits",
    name: "Employee Benefits",
    component: () => import("@/views/services/EmployeeBenefits.vue"),
  },
  {
    path: "/services/business-planning",
    name: "Business Planning",
    component: () => import("@/views/services/BusinessPlanning.vue"),
  },
  {
    path: "/services/compensation",
    name: "Compensation",
    component: () => import("@/views/services/Compensation.vue"),
  },
  {
    path: "/services/executive-benefits",
    name: "Executive Benefits",
    component: () => import("@/views/services/ExecutiveBenefits.vue"),
  },
  // {
  //   path: "/services/financial-planning",
  //   name: "Financial Planning",
  //   component: () => import("@/views/services/FinancialPlanning.vue"),
  // },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "hash",
  routes,
	scrollBehavior() {
        window.scrollTo(0,0);
    }
});

export default router;
