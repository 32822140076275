<template>
  <main>
    <div
      class="
        mx-auto
        max-w-7xl
        w-full
        pt-16
        pb-20
        text-center
        lg:py-48 lg:text-left
      "
    >
      <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
        <h1
          class="
            text-4xl
            tracking-tight
            font-extrabold
            text-gray-900
            sm:text-5xl
            md:text-6xl
            lg:text-5xl
            xl:text-6xl
          "
        >
          <span class="block xl:inline">Wise advice.</span>
          {{ " " }}
          <span class="block text-accent xl:inline">Straightforward approach.</span>
        </h1>
        <p
          class="
            mt-3
            max-w-md
            mx-auto
            text-lg text-gray-500
            sm:text-xl
            md:mt-5 md:max-w-3xl
          "
        >
          For almost 50 years, our team of insurance and financial agents have guided savvy and successful business owners, corporate executives and professionals.
        </p>
        <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div class="">
		  <router-link to="/services"  class="base-btn btn-dark">Learn More</router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        relative
        w-full
        h-64
        sm:h-72
        md:h-96
        lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full
      "
    >
      <img
        class="absolute inset-0 w-full h-full object-cover"
        src="@/assets/images/hero.jpg"
        alt=""
      />
    </div>
  </main>

  

  <!-- Content -->
  <!--<Container>
    <div class="grid lg:grid-cols-2 gap-y-10">
      <section class="lg:pr-12">
        <h2>About Us</h2>
        <p>
          Bryan and Hedden Financial was founded in 1975 as one of the area's
          first independent financial service companies. Our head office is
          located in Burlington, Ontario with partner offices extending across
          Canada.
          <br /><br />
          For almost 50 years, our team of insurance and financial advisors has
          worked with business owners, corporate executives and professionals to
          address their concerns with respect to any one of a number of events
          that could affect the future well being of their business and personal
          wealth.
        </p>
      </section>
      <section>
        <img
          class="w-full h-full object-cover rounded-md"
          src="@/assets/images/cta.jpg"
        />
      </section>
    </div>
  </Container>-->

  <div class="relative bg-gray-800">
    <div
      class="
        h-56
        bg-accent-light
        sm:h-72
        md:absolute md:left-0 md:h-full md:w-1/2
      "
    >
      <img
        class="w-full h-full object-cover"
        src="@/assets/images/cta.jpg"
        alt=""
      />
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="md:ml-auto md:w-1/2 md:pl-10 sm:py-10  lg:py-14">
        <h2
          class="
            text-base
            font-semibold
            uppercase
            tracking-wider
            text-gray-300
            mb-0
          "
        >
          FINANCIAL & INSURANCE 
        </h2>
        <p
          class="
            mt-2
            text-white text-3xl
            font-extrabold
            tracking-tight
            sm:text-4xl
          "
        >
          We’re here to help
        </p>
        <p class="mt-3 text-lg text-gray-300">
          Please contact us if you would like to discuss your insurance or financial goals. Our knowledgeable and dedicated teams of professionals is waiting.
        </p>
        <div class="mt-8">
          <div class="inline-flex rounded-md shadow">
			
			
		  <router-link to="/contact"  class="
                inline-flex
                items-center
                justify-center
                px-5
                py-3
                border border-transparent
                text-base
                font-medium
                rounded-md
                text-gray-900
                bg-white
                hover:bg-gray-50">
			
              Contact Us
              <ExternalLinkIcon
                class="-mr-1 ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
		  </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCard from "../components/ImageCard.vue";
import Container from "@/components/Container.vue";
import { ExternalLinkIcon } from "@heroicons/vue/solid";
export default {
  components: { Container, ImageCard, ExternalLinkIcon },
};
</script>
